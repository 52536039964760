/* eslint-disable @typescript-eslint/no-explicit-any */
import * as SparkMD5 from 'spark-md5';
import * as Geohash from 'geo-hash';
import { LatLng } from 'leaflet';
import GeoUtil from './geo-util';
const EXIF = require('exif-js');

enum XmpAttributes {
    AbsoluteAltitude = 'AbsoluteAltitude',
    RelativeAltitude = 'RelativeAltitude',
    GimbalRollDegree = 'GimbalRollDegree',
    GimbalYawDegree = 'GimbalYawDegree',
    GimbalPitchDegree = 'GimbalPitchDegree',
    CameraYawDegree = 'CameraYawDegree',
    CameraPitchDegree = 'CameraPitchDegree',
    FlightRollDegree = 'FlightRollDegree',
    FlightYawDegree = 'FlightYawDegree',
    FlightPitchDegree = 'FlightPitchDegree',
    CalibratedFocalLength = 'CalibratedFocalLength',
    CalibratedOpticalCenterX = 'CalibratedOpticalCenterX',
    CalibratedOpticalCenterY = 'CalibratedOpticalCenterY',
}

interface XmpData {
    [key: string]: string;
}

export default class FileUtil {
    /**
     * Converts a File (such as from Dropper) to a base64 image string for use in img src
     */
    static fileToBase64Image(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const image = new Image();
                image.src = reader.result as string;
                image.onload = () => {
                    const imageString: string = reader.result as string;
                    resolve(imageString);
                };
                image.onerror = () => {
                    reject('IMAGE READ FAIL');
                };
            };

            reader.readAsDataURL(file);
        });
    }

    /**
     * Converts a base64 image to a File object
     *
     * https://stackoverflow.com/a/15754051/646998
     */
    static base64ImageToFile(base64: string): Promise<File> {
        return new Promise<File>((resolve, _) => {
            const bytesString = atob(base64.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(bytesString.length);
            const array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < bytesString.length; i++) {
                array[i] = bytesString.charCodeAt(i);
            }

            const blob = new Blob([new Uint8Array(array)], { type: 'image/png' });
            const file = new File([blob], 'avatar.png');
            resolve(file);
        });
    }

    // TODO: Refactor this XMP data extraction.
    //       I particularly don't like that `flattenObject` method or `const self = this`
    /* eslint-disable */
    public static readXmpData(file: File): Promise<{ xmp: any; exif: any }> {
        return new Promise((resolve, _) => {
            EXIF.enableXmp();
            const self = this;
            EXIF.getData(file, function (this: any) {
                const attributes = self.extractXMPData(this);
                const exif = EXIF.getAllTags(this);

                if (attributes) {
                    const xmp: XmpData = {};
                    for (const [key, value] of Object.entries(attributes)) {
                        if (key && typeof value === 'string') {
                            Object.keys(XmpAttributes).forEach((attribute) => {
                                if (typeof value === 'string' && key.includes(attribute)) {
                                    xmp[attribute] = value;
                                    if (attribute == XmpAttributes.CameraPitchDegree) {
                                        xmp[XmpAttributes.GimbalPitchDegree] = value;
                                    }
                                    if (attribute == XmpAttributes.CameraYawDegree) {
                                        xmp[XmpAttributes.GimbalYawDegree] = value;
                                    }
                                }
                            });
                        }
                    }
                    resolve({ xmp: xmp, exif: exif });
                } else if (exif) {
                    resolve({ xmp: undefined, exif: exif });
                } else {
                    resolve({ xmp: undefined, exif: undefined });
                }
            });
        });
    }

    private static extractXMPData(data: any) {
        if (
            data &&
            data['xmpdata'] &&
            data['xmpdata']['x:xmpmeta'] &&
            data['xmpdata']['x:xmpmeta']['rdf:RDF'] &&
            data['xmpdata']['x:xmpmeta']['rdf:RDF']['rdf:Description']
        ) {
            const object = data['xmpdata']['x:xmpmeta']['rdf:RDF']['rdf:Description'];
            return this.flattenObject(object);
        } else {
            return undefined;
        }
    }

    private static flattenObject(data: any) {
        const accumulated = {};
        for (var index in data) {
            if (!data.hasOwnProperty(index)) continue;
            if (typeof data[index] == 'object' && data[index] !== undefined) {
                var flatObject = this.flattenObject(data[index]);
                for (var x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;
                    accumulated[index + '.' + x] = flatObject[x];
                }
            } else {
                accumulated[index] = data[index];
            }
        }
        return accumulated;
    }
    /* eslint-enable */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    public static parseLocation(exif: any): LatLng {
        if (
            exif.GPSLatitude === undefined ||
            exif.GPSLongitude === undefined ||
            exif.GPSLatitudeRef === undefined ||
            exif.GPSLongitudeRef === undefined
        ) {
            return new LatLng(0, 0);
        }

        let lat = this.toDecimal(exif.GPSLatitude);
        if (exif.GPSLatitudeRef === 'S') {
            lat = -lat;
        }
        let lon = this.toDecimal(exif.GPSLongitude);
        if (exif.GPSLongitudeRef === 'W') {
            lon = -lon;
        }
        return new LatLng(lat, lon);
    }

    public static getMd5Hash(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const blobSlice = File.prototype.slice;
            const chunkSize = 209715200; // Read in chunks of 2MB
            const chunks = Math.ceil(file.size / chunkSize);
            let currentChunk = 0;
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();

            fileReader.onload = function (e: any) {
                spark.append(e.target.result); // Append array buffer
                currentChunk++;

                if (currentChunk < chunks) {
                    start = currentChunk * chunkSize;
                    end = start + chunkSize >= file.size ? file.size : start + chunkSize;
                    fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
                } else {
                    const hash = spark.end();
                    resolve(hash);
                }
            };

            fileReader.onerror = function () {
                reject('oops, something went wrong');
            };

            let start = currentChunk * chunkSize;
            let end = start + chunkSize >= file.size ? file.size : start + chunkSize;
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        });
    }

    public static computeGeohash(latlng: LatLng): string {
        return Geohash.encode(latlng.lat, latlng.lng, 12);
    }

    public static generateTileLayerKey(filename: string, filehash: string) {
        return (
            filename.substring(0, filename.lastIndexOf('.')) +
            '_' +
            filehash +
            filename.substring(filename.lastIndexOf('.'))
        );
    }

    private static toDecimal(n: any) {
        return n[0].numerator + n[1].numerator / (60 * n[1].denominator) + n[2].numerator / (3600 * n[2].denominator);
    }

    static calculateBoundingBoxFromExifData(xmp: any, exif: any) {
        if (!xmp || !exif) {
            return undefined;
        }

        const longitudeRef = exif['GPSLongitudeRef'];
        const latitudeRef = exif['GPSLatitudeRef'];
        const focalLengthIn35mmFilm = parseFloat(exif['FocalLengthIn35mmFilm']);
        const relativeAltitude = parseFloat(xmp['RelativeAltitude']);
        const absoluteAltitude = parseFloat(xmp['AbsoluteAltitude']);
        const gpsLongitude = GeoUtil.gpsLongitudeDMSToDecimalDegrees(exif['GPSLongitude'], longitudeRef);
        const gpsLatitude = GeoUtil.gpsLatitudeDMSToDecimalDegrees(exif['GPSLatitude'], latitudeRef);
        const gimbalYawDegree = parseFloat(xmp['GimbalYawDegree']);
        const pixelXDimension = parseFloat(exif['PixelXDimension']);
        const pixelYDimension = parseFloat(exif['PixelYDimension']);

        let altitude = absoluteAltitude < absoluteAltitude ? absoluteAltitude : relativeAltitude;

        if (!gpsLongitude || !gpsLatitude) {
            return undefined;
        }

        if (!relativeAltitude || absoluteAltitude) {
            //Get from exif
            altitude = parseFloat(exif['GPSAltitude']);
        }

        return GeoUtil.boundingBoxFromExifData(
            focalLengthIn35mmFilm,
            altitude,
            new LatLng(gpsLatitude, gpsLongitude),
            gimbalYawDegree,
            pixelXDimension,
            pixelYDimension
        );
    }
}
