import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { listingCategoryOptions, ListingType } from '../../../../../api/model';
import { inputMaxValues } from '../../../../../lib/limitation-constants';
import Analytics from '../../../../../lib/user-analytics';
import UserHelper from '../../../../../lib/user-helper';
import { MarkdownTextArea } from '../../../../Shared/markdown-textarea';
import { Multiselect } from '../../../../Shared/multi-select';
import { StyledButton, StyledTransButton, TransparentButton } from '../../../../Shared/styled-button';
import TagInputField from '../../../../Shared/tag-input-field';

interface UploadMapMetadataProps {
    onClickBack: () => void;
    submitted: (
        title: string,
        description: string,
        tags: string[],
        categories: string[],
        hasCheckedTerms: boolean,
        attachment?: File
    ) => void;
    fileName: string;
    title?: string;
    description?: string;
    tags?: string[];
    categories?: string[];
    listingType: ListingType;
}

const UploadMapMetadata = (props: UploadMapMetadataProps) => {
    const [tags, setTags] = useState<string[]>(props.tags || []);
    const [title, setTitle] = useState<string>(props.title || '');
    const [titleLength, setTitleLength] = useState<number>(title.length);
    const [description, setDescription] = useState<string>(props.description || '');
    const [descriptionLength, setDescriptionLength] = useState<number>(UserHelper.getWordCount(description));
    const [categories, setCategories] = useState<string[]>(props.categories || []);
    const [hasCheckedTerms, setHasCheckedTerms] = useState<boolean>(false);
    const [formValid, setFormValid] = useState<boolean>(true);
    const [file, setFile] = useState<File | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);

    const checkForm = () => {
        const valid = title && description && categories.length > 0 && categories.length <= 3 && hasCheckedTerms;
        if (valid) {
            props.submitted(title, description, tags, categories, hasCheckedTerms, file);
            return true;
        }
        return false;
    };

    const setImageTitle = (title: string) => {
        if (title.length < inputMaxValues.MAX_TITLE_CHARACTERS) {
            setTitle(title);
            setTitleLength(title.length);
        }
    };

    const setImageDescription = (description: string) => {
        const descriptionCount = UserHelper.getWordCount(description);
        if (descriptionCount < inputMaxValues.MAX_DESCRIPTION_WORDS) {
            setDescription(description);
            setDescriptionLength(descriptionCount);
        }
    };

    // TODO these can be simplified further
    const options = listingCategoryOptions.map((category) => ({
        value: category.value,
        label: category.label,
    }));

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            e.target.value = '';
        }
    };

    return (
        <MetaDataContainer>
            <Title>Map Details</Title>
            <Content>
                {props.fileName && (
                    <Label className="filename">
                        File name: <strong>{props.fileName}</strong>
                    </Label>
                )}

                <Label>
                    Title <span>*</span>
                </Label>
                <TextField
                    type="text"
                    value={title}
                    onChange={(e) => setImageTitle(e.target.value)}
                    placeholder="Give your map a catchy title"
                    disabled={titleLength === inputMaxValues.MAX_TITLE_CHARACTERS}
                />
                <CharacterCount characters={titleLength} maxCharacters={inputMaxValues.MAX_TITLE_CHARACTERS - 1}>
                    Characters: {titleLength}/{inputMaxValues.MAX_TITLE_CHARACTERS - 1}
                </CharacterCount>
                {!formValid && !title && <Error>Please enter a display name</Error>}

                <Label>
                    Description <span>*</span>
                </Label>
                <MarkdownTextArea value={description} setInputValue={setImageDescription} initialEditorHeight={120} />
                <CharacterCount characters={descriptionLength} maxCharacters={inputMaxValues.MAX_DESCRIPTION_WORDS - 1}>
                    Words: {descriptionLength}/{inputMaxValues.MAX_DESCRIPTION_WORDS - 1}
                </CharacterCount>
                {!formValid && !description && <Error>Please provide a description</Error>}

                <Label>
                    Categories <span>*</span>
                </Label>
                <Multiselect
                    dataTestId="upload-metadata-categories"
                    options={options}
                    onChange={(values) => setCategories(values.map((c) => c.value))}
                    defaultValue={options.filter((o) => categories.includes(o.value))}
                />
                {!formValid && categories.length === 0 && <Error>Please choose at least one category</Error>}
                {!formValid && categories.length > 3 && <Error>Please choose no more than 3 categories</Error>}

                <Label>Tags (optional)</Label>
                <TagInputField onTagInput={setTags} initTags={tags} />
                <CharacterCount characters={tags.length} maxCharacters={inputMaxValues.MAX_TAG_COUNT - 1}>
                    Tags: {tags.length}/{inputMaxValues.MAX_TAG_COUNT - 1}
                </CharacterCount>

                <HiddenFileInput ref={inputRef} type="file" accept=".jpg,.png" onChange={handleFileChange} />

                <AttachItemControl>
                    <Label className="legend">Legend:</Label>
                    {file ? (
                        <label>
                            <i className="fa fa-paperclip mr-2"></i>
                            {file.name}
                            <TrashButton onClick={() => setFile(undefined)} title="Remove attachment">
                                <i className="fa fa-trash"></i>
                            </TrashButton>
                        </label>
                    ) : (
                        <AttachButton onClick={() => inputRef.current?.click()} title="Add">
                            <i className="fa fa-paperclip mr-1"></i>Attach image
                        </AttachButton>
                    )}
                </AttachItemControl>
                <Agreement>
                    <label>
                        <Checkbox
                            type="checkbox"
                            checked={hasCheckedTerms}
                            onChange={() => setHasCheckedTerms(!hasCheckedTerms)}
                        />
                        By submitting this map/image you agree that the content is your own work and/or you have the
                        right to publish this content.
                    </label>
                    <br />
                    For further information refer to our{' '}
                    <A href="https://about.soar.earth/terms" target="_blank">
                        Terms of Service
                    </A>
                </Agreement>
                {!formValid && !hasCheckedTerms && (
                    <Error>Please confirm you have the rights to publish this content</Error>
                )}

                <ActionButtons>
                    <StyledTransButton onClick={props.onClickBack}>
                        <i className="fa fa-chevron-left mr-2"></i>BACK
                    </StyledTransButton>
                    <StyledButton
                        onClick={() => {
                            setFormValid(checkForm());
                            Analytics.Event('Upload', 'Clicked to upload');
                        }}
                    >
                        UPLOAD
                        <i className="fa fa-upload ml-2"></i>
                    </StyledButton>
                </ActionButtons>
            </Content>
        </MetaDataContainer>
    );
};

export default UploadMapMetadata;

const MetaDataContainer = styled.div`
    overflow-y: auto;
    max-height: calc(-75px + 100vh);
    padding: 20px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const Title = styled.h3`
    color: white;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
`;

const Content = styled.div``;

const TextField = styled.input`
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    padding: 8px 5px;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0.25rem;
`;

const Label = styled.p`
    color: white;
    font-size: 16px;
    margin: 4px 0;

    strong {
        color: rgb(179, 179, 179);
    }

    span {
        color: red;
    }

    &.filename {
        font-size: 14px;
    }

    &.legend {
        margin-right: 0;
    }
`;

const Error = styled.p`
    color: red;
    margin: 4px 0;
`;

interface CharacterCountProps {
    characters: number;
    maxCharacters: number;
}

const CharacterCount = styled.span<CharacterCountProps>`
    color: ${(props) =>
        props.characters >= props.maxCharacters ? 'rgba(255, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.5)'};
    font-size: 10px;
    text-align: right;
    display: block;
    margin-bottom: 5px;
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const AttachItemControl = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    label {
        color: white;
        display: flex;
        align-items: center;
        margin: 0px 5px;
    }
`;

const AttachButton = styled(TransparentButton)`
    margin: 5px 15px;
`;

const TrashButton = styled(TransparentButton)`
    margin: 5px 15px;
    border-color: red !important;

    i {
        color: red;
        -webkit-text-fill-color: red;
    }
`;

const Agreement = styled.div`
    margin: 20px 0;
    color: white;

    label {
        color: white;
    }
`;

const A = styled.a`
    color: ${(props) => props.theme.color.yellow} !important;
`;

const ActionButtons = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Checkbox = styled.input`
    margin-right: 5px;
`;
