import React, { useState } from 'react';
import { useEffect } from 'react';
import { isMobileVersion } from '../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { actionUpdatePurchasedListings } from '../../../store/Purchases/actions';
import { selectPurchasedListings } from '../../../store/Purchases/selectors';
import NavigationPanel from '../Shared/navigation-panel';
import { PurchaseListItem } from './purchase-list-item';
import { PulseLoader } from '../../Shared/pulse-loader';

// TODO The no imagery may not make sense anymore as drones removed #4414 - DP
const PurchaseHistory = () => {
    const dispatch = useDispatch();
    const myPurchases = useSelector(selectPurchasedListings);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const updatePurchases = () => {
            setIsLoading(true);
            dispatch(actionUpdatePurchasedListings());
            setIsLoading(false);
        };
        updatePurchases();
    }, [dispatch]);

    return (
        <div>
            <NavigationPanel label="Purchase History" />
            {isLoading && myPurchases ? (
                <LoaderContainer>
                    <PulseLoader />
                </LoaderContainer>
            ) : (
                <>
                    {myPurchases.length > 0 && (
                        <PurchaseTable>
                            <thead>
                                <tr>
                                    {!isMobileVersion && (
                                        <>
                                            <th>Purchase Details</th>
                                            <th>Purchase Date</th>
                                            <th>Download Receipt</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {myPurchases.map((purchase) => (
                                    <PurchaseListItem key={purchase.id} purchase={purchase} />
                                ))}
                            </tbody>
                        </PurchaseTable>
                    )}
                </>
            )}
        </div>
    );
};

export default PurchaseHistory;

const PurchaseTable = styled(Table)`
    background-color: transparent;
    color: white;

    th {
        color: white;
        border-top: none;
        border-bottom: none !important;
    }
`;

const LoaderContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
`;
