import axios from 'axios';
import { LatLng, LatLngBounds } from 'leaflet';
import { OssUploader } from './oss-uploader';

import GeoUtil from '../lib/geo-util';
import Api from './api';
import { DownloadLinkDTO, ListingDTO, ListingType, UploadCredentialsDTO, ListingMetadata } from './model';

export interface GetListingsParams {
    listingType?: ListingType;
    userId?: number | string;
    limit?: number;
    offset?: number;
    keywords?: string;
    category?: string;
    orderBy?: string;
    aoi?: string;
    distance?: number;
}

export interface GetListingsResult {
    listings: ListingDTO[];
    total: number;
}

let CANCEL_TOKEN = axios.CancelToken.source();

export default class ApiListings extends Api {
    public static getListings(params: GetListingsParams): Promise<GetListingsResult> {
        return this.axios
            .get('/v1/listings', { params, cancelToken: CANCEL_TOKEN.token, cache: false })
            .then((res) => {
                const mappedLisstings = res.data.listings.map((listing) => {
                    return this.toListingDTO(listing);
                });
                return { listings: mappedLisstings, total: res.data.total };
            })
            .catch(() => {
                return { listings: [], total: 0 };
            });
    }

    public static getListingsNearby(query?: GetListingsParams): Promise<ListingDTO[]> {
        //default distance is 10km
        const params: GetListingsParams = { distance: 10000, ...query };

        return this.axios
            .get('/v1/listings/nearby', { params, cache: false })
            .then((res) => {
                return res.data.listings.map((listing) => {
                    return this.toListingDTO(listing);
                });
            })
            .catch(() => {
                return [];
            });
    }

    public static cancelGetListings(cancelReason: string) {
        CANCEL_TOKEN.cancel(cancelReason);
        ApiListings.generateAutoCompleteCancelToken();
    }

    public static generateAutoCompleteCancelToken() {
        CANCEL_TOKEN = axios.CancelToken.source();
    }

    public static getListing(id: number | string): Promise<ListingDTO> {
        return this.axios.get('/v1/listings/' + id, { cache: false }).then((res) => {
            return this.toListingDTO(res.data);
        });
    }

    public static toListingDTO(listing: ListingDTO): ListingDTO {
        let bounds: LatLngBounds;
        switch (listing.listingType) {
            case ListingType.TILE_LAYER:
            case ListingType.EXTERNAL_TILE_LAYER:
            case ListingType.WMTS:
            case ListingType.WMS:
            case ListingType.ORDER:
                bounds = GeoUtil.latLngBoundsFromPolygonWKT(listing.geometryWKT);
                break;
            case ListingType.COG:
                bounds = GeoUtil.latLngBoundsFromPolygonWKT(listing.bboxWKT);
                break;
            case ListingType.IMAGE: {
                const position = GeoUtil.latLngFromWKT(listing.geometryWKT);
                if (!position)
                    throw new Error(`Invalid position for listing. Expected LatLng but got: ${listing.geometryWKT}`);
                bounds = position.toBounds(300);
                listing.position = position;
                break;
            }
            default:
                bounds = new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
                listing.listingType = ListingType.NOT_SUPPORTED;
        }
        listing.tags = listing.tags ?? [];
        listing.boundingBox = bounds;
        listing.dateUploaded = new Date(listing.createdAt * 1000);
        return listing;
    }

    public static getListingsCached(params: GetListingsParams): Promise<ListingDTO[]> {
        return this.axios.get('/v1/listings', { params, cache: true }).then((res) => {
            return res.data.listings.map((listing) => {
                return this.toListingDTO(listing);
            });
        });
    }

    public static getListingsByUserId(userId: string): Promise<ListingDTO[]> {
        const listings = this.getListingsCached({ userId }).then((listings) =>
            listings.filter((l) => l.listingType !== ListingType.NOT_SUPPORTED)
        );
        return listings;
    }

    public static getSimilarUserListingsByMapId(mapId: number, limit = 20): Promise<ListingDTO[]> {
        return this.getListing(mapId).then((res) => {
            return this.getListingsCached({ userId: res.userId, limit: limit }).then((res) => {
                return res;
            });
        });
    }

    public static getSubdomainTileLayers(): Promise<GetListingsResult> {
        return this.getListings({});
    }

    public static searchListings(
        limit: number,
        offset: number,
        keywords?: string,
        category?: string,
        aoi?: string,
        orderBy?: string
    ): Promise<GetListingsResult> {
        const params = {
            limit,
            offset: offset !== 0 ? offset : undefined,
            keywords: keywords ? keywords : undefined,
            category: category ? category : undefined,
            aoi,
            orderBy,
        };
        ApiListings.cancelGetListings('Cancel search has changed');
        return this.getListings(params);
    }

    public static sideDrawerSearchFeaturedListings(params: GetListingsParams): Promise<ListingDTO[]> {
        ApiListings.cancelGetListings('Cancel search has changed');
        return this.getListings(params).then((res) => {
            return res.listings;
        });
    }

    public static getSimilarListings(listingId: number): Promise<ListingDTO[]> {
        return this.axios
            .get(`/v1/listings/${listingId}/similar`, { cache: true })
            .then((res) => {
                return res.data.listings.map((listing) => {
                    return this.toListingDTO(listing);
                });
            })
            .catch(() => {
                return [];
            });
    }

    public static loadMoreListings(
        limit: number,
        offset: number,
        keywords?: string,
        category?: string[],
        aoi?: string,
        orderBy?: string
    ): Promise<GetListingsResult> {
        const params = {
            limit,
            offset: offset !== 0 ? offset : undefined,
            keywords: keywords ? keywords : undefined,
            category: category ? category.map((s) => s.toLocaleLowerCase()).join(',') : undefined,
            aoi,
            orderBy,
        };
        ApiListings.cancelGetListings('Cancel loading more listings');
        return this.getListings(params);
    }

    public static getDownloadUrl(listingId: number): Promise<DownloadLinkDTO> {
        return this.axios.get(`/v1/listings/${listingId}/download`, { cache: false }).then((result) => {
            return result.data;
        });
    }

    public static postListingAndGetUploadCredentials(
        listingType: ListingType,
        filename: string,
        listing: ListingMetadata,
        metadata: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additionalInfo: any
    ): Promise<UploadCredentialsDTO> {
        const data = {
            listingType,
            filename,
            ...listing,
            metadata,
            ...additionalInfo,
        };
        return this.axios.post('/v1/listings/upload', data, { cache: false, errorOnInvalidAuth: true }).then((res) => {
            return res.data;
        });
    }

    public static createFileUploadCredential(
        listingId: number,
        type: 'LEGEND',
        filename: string,
        title = '',
        description = ''
    ): Promise<UploadCredentialsDTO> {
        const params = {
            type,
            listingType: type,
            filename,
            title,
            description,
        };

        return this.axios.post(`/v1/listings/${listingId}/files`, params).then((res) => {
            return res.data;
        });
    }

    public static updateListing(listingId: number, metadata: ListingMetadata): Promise<ListingDTO> {
        return this.axios.put(`/v1/listings/${listingId}`, { metadata }).then((res) => {
            return res.data;
        });
    }

    public static resubmitListing(listingId: number, metadata: ListingMetadata): Promise<boolean> {
        return this.axios.put(`/v1/listings/${listingId}/reSubmit`, { metadata }).then((res) => {
            return res.data;
        });
    }

    public static async addFile(listingId: number, file: File): Promise<boolean> {
        const credential = await ApiListings.createFileUploadCredential(listingId, 'LEGEND', file.name);
        const attachmentOssUploader = new OssUploader(credential);
        await attachmentOssUploader.uploadFileToStorage(file, `${credential.path}/${file.name}`);
        return true;
    }

    public static async removeFile(listingId: number, fileId: number): Promise<boolean> {
        await this.axios.delete(`/v1/listings/${listingId}/files/${fileId}`);
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async reportMap(listingId: number | string, reason: string): Promise<any> {
        return this.axios.post(`/v1/listings/${listingId}/report`, { reason }).then((res) => {
            return res.data;
        });
    }

    public static async sendShareUploadToEmail(listingId: number, emails: string[]): Promise<boolean> {
        return this.axios.post(`/v1/listings/${listingId}/email-share`, { emails }).then((res) => {
            return res.data;
        });
    }
}
